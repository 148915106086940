import React, { useEffect, useState } from "react";
import Pagination from "../shared/pagination";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getUserBets } from "originals/originals-games-api";

const UserLimboRewiredBets = ({ match, ...rest }) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const data = await getUserBets(match.params.id, {
          page,
          type: "limbo",
        });
        setData({ total_pages: data.pages, bets: data.records });
      } catch (err) {
        toast.error(err.message);
      }
      setIsLoading(false);
    };
    fetch();
  }, [page, match.params.id]);

  return (
    <div style={{ position: "relative", "min-height": "500px" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Round ID</Th>
            <Th>Bet Placed At</Th>
            <Th>Bet Settled At</Th>
            <Th>Amount</Th>
            <Th>Target Multiplier</Th>
            <Th>Winnings</Th>
            <Th>Multiplier Point</Th>
            <Th>Free Bet</Th>
            <Th>Profit</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.bets.map(
            ({
              game,
              wallet_params,
              remoteRoundId,
              winnings,
              profit,
              amount,
              currency_type,
              bet_placed_at,
              bet_settled_at,
            }) => {
              const payout = +winnings > 0 ? +profit : -1 * +amount;
              const winningsValue = parseFloat(
                +winnings > 0 ? +winnings : -amount
              ).toFixed(2);

              return (
                <Tr key={remoteRoundId}>
                  <Td>
                    {wallet_params?.free_bet ? (
                      remoteRoundId
                    ) : (
                      <Link
                        to={`/casino/game-provider-rounds/${remoteRoundId}`}
                      >
                        {remoteRoundId}
                      </Link>
                    )}
                  </Td>
                  <Td>{format.withSeconds(bet_placed_at)}</Td>
                  <Td>{format.withSeconds(bet_settled_at)}</Td>
                  <Td>
                    {amount} {currency_type}
                  </Td>
                  <Td>{game?.target}</Td>
                  <Td className={+profit >= 0 ? "good" : ""}>
                    {winningsValue} {wallet_params?.currency}
                  </Td>
                  <Td>{(+game?.multiplier).toFixed(2)}</Td>
                  <Td>{wallet_params?.free_bet ? "yes" : "no"}</Td>
                  <Td className={+profit >= 0 ? "good" : "bad"}>
                    {(+payout).toFixed(2)} {currency_type}
                  </Td>
                </Tr>
              );
            }
          )}
        </Tbody>
      </Table>

      <Pagination
        total_pages={data.total_pages}
        update={(page) => setPage(page)}
      />
    </div>
  );
};

export default UserLimboRewiredBets;
