import { permissionGroups } from "app/permissions-fragment";

import Notes from "user/notes";
import RoutePermissions from "user/route-permission";
import UserAntiFraud from "user/anti-fraud";
import UserBonuses from "user/bonuses";
import UserBoundRoundData from "user/bonus-rounds";
import UserCrashBets from "user/user-crash-bets";
import UserCrashRoomBets from "user/user-crash-room-bets";
import UserDeposits from "user/deposits";
import UserDiceBets from "user/user-dice-bets";
import UserDoubleBets from "user/user-double-bets";
import UserDoubleV2Bets from "user/user-double-v2-bets";
import UserFinancials from "user/financials/financials";
import UserFingerprints from "user/fingerprints";
import UserGameProviderRounds from "user/user-game-history";
import UserInfo from "user/Info";
import UserKYC from "user/kyc";
import UserLoyalty from "user/loyalty";
import UserMinesBets from "user/user-mines-bets";
import UserMinesBetsV2 from "user/user-mines-bets-v2";
import UserTowerBets from "user/user-tower-bets";
import UserMysteryBoxData from "user/mystery-boxes";
import UserMysteryBoxOpens from "user/mystery-box-opens";
import UserPaymentBlacklists from "user/payment-blacklists";
import UserPermissions from "user/permissions";
import UserReferrals from "user/referrals";
import UserRewardInventory from "user/reward-inventory";
import CashbackRewardPeriods from "user/cashback-reward-periods";
import UserRoles from "user/roles";
import UserSoftswissFreeSpins from "user/softswiss-free-spins";
import UserTransactions from "user/transactions";
import UserWithdrawals from "user/withdrawals";
import UserPlinkoBets from "user/user-plinko-bets";
import UserLimboBets from "user/user-limbo-bets";
import UserSlideBets from "user/user-slide-bets";
import UserFruitsBets from "user/user-fruits-bets";
import UserFruitsSliceBets from "user/user-fruits-slice-bets";
import UserCoinFlipBets from "user/user-coin-flip-bets";
import UserFortuneDoubleBets from "user/user-fortune-double-bets";
import OverdraftAlerts from "user/overdraft-alerts";
import UserNeymarCrashBets from "user/user-neymar-crash-bets";
import UserCrashRewiredBets from "user/user-crash-rewired-bets";
import UserPendingBets from "user/user-pending-bets";
import UserOTPFingerprints from "user/otp-fingerprints";
import UserLimboRewiredBets from "user/user-limbo-rewired-bets";

const USERS = permissionGroups.USERS;

export const switchConfig = [
  {
    props: { group: USERS.INFO, exact: true },
    path: "/users/:id/info",
    component: UserInfo,
  },
  {
    props: { group: USERS.KYC },
    path: "/users/:id/documents",
    component: UserKYC,
  },
  {
    props: { group: USERS.ROLES },
    path: "/users/:id/route-permissions",
    component: RoutePermissions,
  },
  {
    props: { group: USERS.BALANCES },
    path: "/users/:id/financials",
    component: UserFinancials,
  },
  {
    props: { group: USERS.ROLES },
    path: "/users/:id/permissions",
    component: UserPermissions,
  },
  {
    props: { group: USERS.MUTUAL },
    path: "/users/:id/anti-fraud",
    component: UserAntiFraud,
  },
  {
    props: { group: USERS.BONUSES },
    path: "/users/:id/bonus-rounds",
    component: UserBoundRoundData,
  },
  {
    props: { group: USERS.TRANSACTIONS },
    path: "/users/:id/transactions",
    component: UserTransactions,
  },
  {
    props: { group: USERS.DEPOSITS },
    path: "/users/:id/deposits",
    component: UserDeposits,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/crash-bets",
    component: UserCrashBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/crash-II-bets",
    component: UserCrashRoomBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/neymar-crash-bets",
    component: UserNeymarCrashBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/crash-rewired-bets",
    component: UserCrashRewiredBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/double-bets",
    component: UserDoubleBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/double-v2-bets",
    component: UserDoubleV2Bets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/mines-bets",
    component: UserMinesBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/bets-pending",
    component: UserPendingBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/mines-bets-v2",
    component: UserMinesBetsV2,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/tower-bets",
    component: UserTowerBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/coin-flip-bets",
    component: UserCoinFlipBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/slide-bets",
    component: UserSlideBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/fortune-double-bets",
    component: UserFortuneDoubleBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/fruits-bets",
    component: UserFruitsBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/fruits-slice-bets",
    component: UserFruitsSliceBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/dice-bets",
    component: UserDiceBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/plinko-bets",
    component: UserPlinkoBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/limbo-bets",
    component: UserLimboBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/limbo-rewired-bets",
    component: UserLimboRewiredBets,
  },
  {
    props: { group: USERS.BETS },
    path: "/users/:id/game-provider-rounds",
    component: UserGameProviderRounds,
  },
  {
    props: { group: USERS.SOFTSWISS_FREE_SPINS },
    path: "/users/:id/softswiss-free-spins",
    component: UserSoftswissFreeSpins,
  },
  {
    props: { group: USERS.FINGERPRINTS },
    path: "/users/:id/fingerprints",
    component: UserFingerprints,
  },
  {
    props: { group: USERS.NOTES },
    path: "/users/:id/notes",
    component: Notes,
  },
  {
    props: { group: USERS.WITHDRAWALS },
    path: "/users/:id/withdrawals",
    component: UserWithdrawals,
  },
  {
    props: { group: USERS.REWARDS },
    path: "/users/:id/reward-inventory",
    component: UserRewardInventory,
  },
  {
    props: { group: USERS.REWARDS },
    path: "/users/:id/cashback-reward-periods",
    component: CashbackRewardPeriods,
  },
  {
    props: { group: USERS.MYSTERY_BOX_BALANCES },
    path: "/users/:id/mystery-boxes",
    component: UserMysteryBoxData,
  },
  {
    props: { group: USERS.MYSTERY_BOX_OPENS },
    path: "/users/:id/mystery-box-opens",
    component: UserMysteryBoxOpens,
  },
  {
    props: { group: USERS.REFERRALS },
    path: "/users/:id/referrals",
    component: UserReferrals,
  },
  {
    props: { group: USERS.LOYALTY },
    path: "/users/:id/loyalty",
    component: UserLoyalty,
  },
  {
    props: { group: USERS.PAYMENT_METHODS },
    path: "/users/:id/payment-methods",
    component: UserPaymentBlacklists,
  },
  {
    props: { group: USERS.BONUSES },
    path: "/users/:id/BONUSES",
    component: UserBonuses,
  },
  {
    props: { group: USERS.ROLES },
    path: "/users/:id/roles",
    component: UserRoles,
  },
  {
    props: { group: USERS.OVERDRAFTS },
    path: "/users/:id/overdraft-alerts",
    component: OverdraftAlerts,
  },
  {
    props: { group: USERS.FINGERPRINTS },
    path: "/users/:id/otp",
    component: UserOTPFingerprints,
  },
];

export default switchConfig;
